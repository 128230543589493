<template>
  <Component :is="tag" :class="roundedClassesMap[rounded]" class="relative px-4">
    <slot />
  </Component>
</template>

<script lang="ts" setup>
type PropType = {
  tag?: string
  rounded?: 'full' | 'top' | 'bottom' | 'none'
}

withDefaults(defineProps<PropType>(), {
  tag: 'div',
  rounded: 'none'
})

const roundedClassesMap: Record<NonNullable<PropType['rounded']>, string> = {
  full: 'rounded-4xl',
  top: 'rounded-t-4xl',
  bottom: 'rounded-b-4xl',
  none: 'rounded-none'
}
</script>
