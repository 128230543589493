<template>
  <div class="flex flex-col items-center">
    <svg
      class="mb-2"
      width="41"
      height="45"
      viewBox="0 0 41 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.03532 22.38C6.05639 15.4314 11.8512 9.78476 19.0172 9.80649C26.1832 9.82823 31.9437 15.5099 31.9226 22.4586C31.9015 29.4072 26.1067 35.0538 18.9407 35.0321C11.7747 35.0104 6.01424 29.3287 6.03532 22.38ZM19.0218 8.3065C11.0622 8.28236 4.55901 14.564 4.53532 22.3755C4.51163 30.187 10.9765 36.508 18.9361 36.5321C22.4507 36.5428 25.6813 35.324 28.1955 33.287L36.3707 41.3133C36.6663 41.6035 37.1411 41.5991 37.4313 41.3035C37.7215 41.008 37.7172 40.5331 37.4216 40.2429L29.3124 32.2814C31.844 29.7503 33.411 26.2903 33.4226 22.4631C33.4463 14.6516 26.9814 8.33064 19.0218 8.3065Z"
        fill="var(--colors-secondary)"
      />
      <rect x="20.7803" width="20" height="20" rx="10" fill="var(--colors-primary)" />
      <path
        d="M26.3764 5.5951C26.5995 5.37195 26.9613 5.37195 27.1845 5.5951L30.7804 9.19059L34.3764 5.5951C34.5823 5.38911 34.9065 5.37327 35.1306 5.54757L35.1845 5.5951C35.4076 5.81826 35.4076 6.18007 35.1845 6.40322L31.589 9.99916L35.1845 13.5951C35.3905 13.8011 35.4063 14.1252 35.232 14.3494L35.1845 14.4032C34.9613 14.6264 34.5995 14.6264 34.3764 14.4032L30.7804 10.8077L27.1845 14.4032C26.9785 14.6092 26.6544 14.6251 26.4302 14.4508L26.3764 14.4032C26.1532 14.1801 26.1532 13.8183 26.3764 13.5951L29.9718 9.99916L26.3764 6.40322C26.1704 6.19723 26.1545 5.8731 26.3288 5.64893L26.3764 5.5951Z"
        fill="var(--backgroundColor-primary)"
      />
    </svg>

    <p class="text-center text-sm text-text-secondary">
      <slot>
        По вашему запросу<br />
        ничего не найдено
      </slot>
    </p>
  </div>
</template>
