import type { HttpRequestAbortMap, HttpRequestAbortItem } from './model'

class CancelRequestService {
  private abortControllers: HttpRequestAbortMap = new Map()

  public addAbortController(key: string, needCancelOnRouteChange?: boolean) {
    this.abortControllers.set(key, {
      controller: new AbortController(),
      needCancelOnRouteChange
    })
  }

  public deleteAbortController(key: string) {
    this.abortControllers.delete(key)
  }

  public getAbortControllerSignal(key: string) {
    return this.abortControllers.get(key)?.controller?.signal
  }

  public callControllerAbort(key: string) {
    if (!this.abortControllers.has(key)) return

    this.abortControllers.get(key)?.controller?.abort()
    this.deleteAbortController(key)
  }

  public abortRequestsByParam(param: keyof HttpRequestAbortItem) {
    const mapValues = this.abortControllers.entries()

    for (const [key, item] of mapValues) {
      if (param in item && item[param]) {
        this.callControllerAbort(key)
      }
    }
  }
}

const cancelRequestService = new CancelRequestService()

export { cancelRequestService }
