import { defaultErrorsHandbook } from '../config/'
import type { ErrorCodes, ErrorHandbookItem, ErrorResponse } from '../model'

export class ErrorsHandbookBuilder {
  private readonly _httpErrorCode: ErrorCodes
  private readonly _defaultErrorMessage = 'Что-то пошло не так. Пожалуйста, попробуйте позднее'

  constructor(httpErrorCode: ErrorCodes) {
    this._httpErrorCode = httpErrorCode
  }

  private get _defaultError() {
    return (
      defaultErrorsHandbook.get(this._httpErrorCode) || {
        code: this._httpErrorCode,
        message: this._defaultErrorMessage
      }
    )
  }

  private _errors = {} as Record<number, ErrorResponse>

  public withErrorCode(errorCode: number, errorMessage?: string) {
    this._errors = {
      ...this._errors,
      [errorCode]: (errorMessage && { message: errorMessage, code: this._httpErrorCode }) || {
        message: this._defaultErrorMessage,
        code: this._httpErrorCode
      }
    }

    return this as ErrorsHandbookBuilder
  }

  public build() {
    return {
      [this._httpErrorCode]: {
        default: this._defaultError,
        ...this._errors
      } as ErrorHandbookItem
    }
  }
}
