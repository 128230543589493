import { useCookies } from '@vueuse/integrations/useCookies'
import { ofetch } from 'ofetch'
import { CookiesName, useRuntimeConfig } from '../../config'

const createHeaders = (): HeadersInit => {
  const headers: HeadersInit = {}
  const authToken = useCookies().get(CookiesName.authToken)

  if (authToken) headers['Authorization'] = `Bearer ${authToken}`

  return headers
}

export const http = ofetch.create({
  baseURL: import.meta.env.PROD ? `${useRuntimeConfig()?.VITE_APP_BASE_URL}api/` : '/api/',
  onRequest({ options }) {
    options.headers = createHeaders()
  }
})
