<template>
  <div
    ref="datePickerContainer"
    class="relative h-full overflow-y-scroll bg-primary"
    @scroll="onPickerScroll"
    @touchmove="onInteractionTimerReload"
    @touchend="onInteractionTimerReload"
    @mousemove="onInteractionTimerReload"
  >
    <UiRoundedHeader sticky>
      <slot name="header" />
      <UiDateHelpers v-model="selectedDate" class="mb-3" @update:model-value="onHelperSelect" />
      <ul class="grid grid-cols-7">
        <li
          v-for="(day, index) in headerDays"
          :key="day"
          :class="
            index === 6 || index === 5
              ? '!text-calendar-text-weekend'
              : 'text-calendar-text-primary'
          "
        >
          <p class="text-center">
            {{ day }}
          </p>
        </li>
      </ul>
    </UiRoundedHeader>
    <UiContainer class="flex flex-col gap-y-2 pt-4">
      <div
        v-for="month in visibleMonths"
        :key="getMonth(month) + getYear(month)"
        class="overflow-hidden rounded-xl border border-secondary py-2"
      >
        <header class="mb-4 text-center">
          <UiTitle severity="h3">
            <UiDate :value="month" template="LLLL yyyy" class="capitalize" />
          </UiTitle>
        </header>
        <ul class="grid grid-cols-7">
          <li v-for="day in getDatesInMonth(month)" :key="day.value.toString()">
            <button
              type="button"
              class="relative h-10 w-full rounded-xl text-calendar-text-primary hover:!no-underline"
              :disabled="isAfter(today, day.value)"
              :class="[
                {
                  'text-calendar-text-weekend': day.isWeekend,
                  'bg-transparent !text-calendar-text-inactive':
                    !day.isThisMonth || isAfter(today, day.value),
                  '!font-bold text-calendar-text-active': day.isToday,
                  'z-10 !bg-calendar-active !text-button-primary-text': dateIsActive(day),
                  'z-0 ml-[-20%] !w-[140%] !rounded-none bg-calendar-range':
                    !dateIsActive(day) && dateIsInRange(day)
                },
                dateIsActive(day) && ACTIVE_DATE_CLASS
              ]"
              @click="selectDate(day)"
            >
              {{ day.text }}
            </button>
          </li>
        </ul>
      </div>
    </UiContainer>
    <UiContainer class="sticky bottom-12 left-0 z-20">
      <TransitionFade>
        <UiButton
          v-show="showConfirmButton"
          severity="primary"
          class="w-full"
          @click="onConfirmClick"
        >
          <slot name="confirm-text"> Показать мероприятия </slot>
        </UiButton>
      </TransitionFade>
    </UiContainer>
  </div>
</template>

<script lang="ts" setup>
import { TransitionFade } from '@morev/vue-transitions'
import {
  getDaysInMonth,
  addMonths,
  getMonth,
  getYear,
  set,
  isWeekend,
  previousMonday,
  isBefore,
  isAfter,
  isToday,
  isSameMonth,
  isSameDay,
  isWithinInterval
} from 'date-fns'
import throttle from 'lodash/throttle.js'
import type { Nullable, Undefinable } from 'ts-helpers'
import { computed, ref } from 'vue'
import { getMilliseconds } from '../lib'
import UiButton from './UiButton.vue'
import UiContainer from './UiContainer.vue'
import UiDate from './UiDate.vue'
import UiDateHelpers from './UiDateHelpers.vue'
import UiRoundedHeader from './UiRoundedHeader.vue'
import UiTitle from './UiTitle.vue'

type DatePickerDateValue = {
  text: string | number
  value: Date
  isThisMonth: boolean
  isWeekend: boolean
  isToday: boolean
}

type PropType = {
  range?: boolean
  needConfirm?: boolean
  modelValue?: Date | Date[]
}

type EmitType = {
  (e: 'update:modelValue', date: Date | Date[]): void
  (e: 'close'): void
}

const props = withDefaults(defineProps<PropType>(), {
  range: false,
  needConfirm: false,
  modelValue: undefined
})
const emit = defineEmits<EmitType>()

//header
//обработка хелперов
/**
 * добавляет в список отоброжаемых месяцев
 * дефолтный список месяцев
 */
const addDefaultInvisibleMonth = () => {
  const monthIsNotVisible = !visibleMonths.value.find((date) =>
    isSameMonth(date, selectedDate.value as NonNullable<Date>)
  )

  if (monthIsNotVisible) {
    visibleMonths.value.unshift(...createDefaultMonths())
  }
}
/**
 * удаляет лишние месяца,
 * которые идут после дефолтных
 */
const removeExcessMonth = () => {
  setTimeout(() => {
    const visibleMonthLength = createDefaultMonths().length
    visibleMonths.value.splice(visibleMonthLength, visibleMonths.value.length - visibleMonthLength)
  }, 200)
}

const onHelperSelect = () => {
  addDefaultInvisibleMonth()
  goToActiveDate()
  removeExcessMonth()
}

// подсказки дней
const headerDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

//logic
// показываем текущий + 6 месяцев
// по мере скролла добавляем или удаляем месяцы из массива

const datePickerContainer = ref<Nullable<HTMLElement>>(null)
const today = new Date()

/**
 * создает текущий месяц + 6 далее
 */
const createDefaultMonths = (): Date[] => [
  today,
  addMonths(today, 1),
  addMonths(today, 2),
  addMonths(today, 3),
  addMonths(today, 4),
  addMonths(today, 5),
  addMonths(today, 6)
]
const visibleMonths = ref<Date[]>(createDefaultMonths())

/**
 * проверяет, что даты активны
 * @param date
 */
const dateIsActive = (date: DatePickerDateValue) => {
  if (Array.isArray(selectedDate.value)) {
    return (
      date.isThisMonth &&
      (isSameDay(date.value, selectedDate.value[0]) || isSameDay(date.value, selectedDate.value[1]))
    )
  }

  return date.isThisMonth && isSameDay(date.value, selectedDate.value as Date)
}
/**
 * проверяет, что дата в выбранном диапазоне
 * @param date
 */
const dateIsInRange = (date: DatePickerDateValue) => {
  if (!props.range || !Array.isArray(selectedDate.value)) return false

  return isWithinInterval(date.value, {
    start: selectedDate.value[0],
    end: selectedDate.value[1]
  })
}

// выбор даты
const ACTIVE_DATE_CLASS = 'is-active'
const selectedDate = ref<Nullable<Date | Date[]>>(props.modelValue ?? null)
/**
 * скроллит календарь к ативной дате
 */
const goToActiveDate = () => {
  disableScrollHandler = true
  const getActiveDate = () => {
    if (!datePickerContainer.value) return

    return datePickerContainer.value.querySelector(`.${ACTIVE_DATE_CLASS}`)
  }

  setTimeout(() => {
    getActiveDate()?.scrollIntoView({
      block: 'center',
      behavior: 'smooth'
    })
    disableScrollHandler = false
  })
}
/**
 * выбирает дату
 * @param day
 */
// таймер, дающий время для выбора второй даты в диапазоне
let lastDateSelectionTimer: Undefinable<NodeJS.Timeout> = undefined
/**
 * сбрасывает таймер для выбора второй даты
 */
const resetLastDateSelectionTimer = () => {
  clearTimeout(lastDateSelectionTimer)
  lastDateSelectionTimer = undefined
}
/**
 * стартует таймер для выбора второй даты
 */
const startDateSelectionTimer = () => {
  lastDateSelectionTimer = setTimeout(
    () => resetLastDateSelectionTimer(),
    getMilliseconds.inSeconds(1.5)
  )
}

/**
 * перезапускаем таймер при взаимодействии пользователя со страницей
 */
const onInteractionTimerReload = () => {
  if (!lastDateSelectionTimer || !props.range) return

  resetLastDateSelectionTimer()
  startDateSelectionTimer()
}

/**
 * выбор даты
 * @param day
 */
const selectDate = (day: DatePickerDateValue) => {
  const isAnotherMonth = !day.isThisMonth
  const isFirstClick = !selectedDate.value
  const isClickAfterTimeout = selectedDate.value && !lastDateSelectionTimer
  const isSameDateRepeatedClick =
    selectedDate.value &&
    !Array.isArray(selectedDate.value) &&
    selectedDate.value.toString() === day.value.toString()

  const setDayAsFirstDate = () => (selectedDate.value = day.value)
  const setDateRange = () => {
    selectedDate.value = [selectedDate.value as Date, day.value]
    selectedDate.value.sort((a, b) => {
      if (isBefore(a, b)) return -1
      if (isAfter(a, b)) return 1

      return 0
    })
  }
  const selectDate = () => {
    if (!isAnotherMonth && isSameDateRepeatedClick) {
      resetLastDateSelectionTimer()

      return (selectedDate.value = null)
    }

    if (isFirstClick || isClickAfterTimeout || !props.range) {
      startDateSelectionTimer()
      setDayAsFirstDate()

      return isAnotherMonth ? goToActiveDate() : undefined
    }

    setDateRange()

    return resetLastDateSelectionTimer()
  }

  selectDate()
  !props.needConfirm && confirmDate()
}
/**
 * возвращает дни в месяце
 * @param month
 */
const getDatesInMonth = (month: Date): DatePickerDateValue[] => {
  const createDate = (n: number, date: Date, isThisMonth: boolean): DatePickerDateValue[] => {
    return new Array(n).fill(0).map((_, i) => {
      const dateValue = set(date, { date: i + 1 })

      return {
        text: i + 1,
        value: dateValue,
        isThisMonth,
        isWeekend: isWeekend(dateValue),
        isToday: isToday(dateValue)
      }
    })
  }

  const prevMonth = addMonths(month, -1)
  const nextMonth = addMonths(month, 1)

  return [
    ...createDate(getDaysInMonth(prevMonth), prevMonth, false).filter(({ value }) => {
      const lastMonday = previousMonday(set(month, { date: 2 }))

      return !isBefore(value, lastMonday)
    }),
    ...createDate(getDaysInMonth(month), month, true),
    ...createDate(getDaysInMonth(nextMonth), nextMonth, false)
  ].filter((_, i) => i <= 41)
}
/**
 * обрабатывает скролл календаря
 */
let disableScrollHandler = false

const onPickerScroll = throttle(function () {
  if (disableScrollHandler || !datePickerContainer.value) return

  const { scrollTop, scrollHeight, clientHeight } = datePickerContainer.value

  const LOADING_BORDER = 300
  const topBorder = scrollTop <= LOADING_BORDER
  const bottomBorder = scrollHeight - (scrollTop + clientHeight) <= LOADING_BORDER

  if (topBorder) {
    onScrollTopHandler()
    onScrollTopHandler()
  }
  if (bottomBorder) {
    onScrollDownHandler()
    onScrollDownHandler()
  }
}, 10)

const addTimeout = () => {
  disableScrollHandler = true
  setTimeout(() => (disableScrollHandler = false))
}
/**
 * добавляет месяц сверху
 */
const onScrollTopHandler = () => {
  const firstMonth = visibleMonths.value[0]

  if (isSameMonth(firstMonth, today)) return

  visibleMonths.value.unshift(addMonths(firstMonth, -1))
  visibleMonths.value.pop()
  addTimeout()
}
/**
 * добавляет месяц снизу
 */
const onScrollDownHandler = () => {
  const lastMonth = visibleMonths.value[visibleMonths.value.length - 1]
  visibleMonths.value.push(addMonths(lastMonth, 1))
  visibleMonths.value.shift()
  addTimeout()
}

// кнопка подтверждения
const showConfirmButton = computed(() => props.needConfirm && selectedDate.value)
/**
 * выбрасывает выбранную дату наружу
 */
const confirmDate = () => selectedDate.value && emit('update:modelValue', selectedDate.value)
const onConfirmClick = () => {
  confirmDate()
  emit('close')
}
</script>
