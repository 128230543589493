import type { UserDTO } from '@/5_entities/User'

export const stringIsArray = (ageInterval: UserDTO['ageInterval']) => {
  let isArray = false

  try {
    isArray =
      !!Array.isArray(ageInterval) ||
      !!(ageInterval && Array.isArray(JSON.parse(String(ageInterval))))
  } catch {
    isArray = false
  }

  return isArray
}
