import type { ErrorCodes } from '../../api'

export enum AppMessengerMessageNames {
  open = 'open',
  close = 'close',
  navigate = 'navigate',
  widgetOpen = 'widget:open',
  widgetClose = 'widget:close',
  widgetOrder = 'widget:order',
  errorIntegration = 'error:integration',
  errorNotFound = 'error:notFound'
}

class AppMessenger {
  private readonly _messagePrefix = 'inticketsAfisha:'
  private port?: MessagePort

  constructor() {
    window.addEventListener('message', (event: MessageEvent) => {
      if (event.data === 'capturePort' && event.ports[0]) {
        this.port = event.ports[0]
      }
    })
  }

  private _createMessage = (messageName: AppMessengerMessageNames, params?: object) => {
    const message = JSON.stringify({ name: `${this._messagePrefix}${messageName}`, params })

    this.port ? this.port.postMessage(message) : parent.postMessage(message, '*')
  }

  public open() {
    return this._createMessage(AppMessengerMessageNames.open)
  }

  public close() {
    return this._createMessage(AppMessengerMessageNames.close)
  }

  public navigate(routeName: string) {
    return this._createMessage(AppMessengerMessageNames.navigate, { routeName })
  }

  public widget = {
    open: () => this._createMessage(AppMessengerMessageNames.widgetOpen),
    close: () => this._createMessage(AppMessengerMessageNames.widgetClose),
    order: () => this._createMessage(AppMessengerMessageNames.widgetOrder)
  }

  public error = {
    integration: {
      partnerKey: () =>
        this._createMessage(AppMessengerMessageNames.errorIntegration, {
          message: 'partner key not set or invalid'
        }),
      custom: (content: object) =>
        this._createMessage(AppMessengerMessageNames.errorNotFound, content)
    },
    http: {
      notFound: (code: ErrorCodes, url: string) =>
        this._createMessage(AppMessengerMessageNames.errorNotFound, { code, url })
    }
  }
}

export const appMessenger = new AppMessenger()
