<template>
  <div>
    <div ref="visibleContent" @click="!showFull && (showFull = true)">
      <div :class="!showFull && 'line-clamp-3'">
        <slot />
      </div>
    </div>
    <UiButton
      v-if="showButton"
      text
      class="-ml-3 -mt-3 !p-3 text-sm text-text-primary"
      @click="showFull = !showFull"
    >
      {{ showFull ? 'Скрыть полное описание' : 'Ещё' }}
    </UiButton>

    <div ref="defaultContent" class="absolute -z-10 opacity-0">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Nullable } from 'ts-helpers'
import { onMounted, ref } from 'vue'
import UiButton from './UiButton.vue'

const showFull = ref(false)
const showButton = ref(false)
const defaultContent = ref<Nullable<HTMLElement>>(null)
const visibleContent = ref<Nullable<HTMLElement>>(null)
const calculateButtonVisibility = () => {
  if (!visibleContent.value || !defaultContent.value) return

  const { offsetHeight: defaultHeight } = defaultContent.value
  const { offsetHeight: visibleHeight } = visibleContent.value
  showButton.value = defaultHeight > visibleHeight
}
const hideDefaultContent = () => {
  defaultContent.value?.remove()
}

onMounted(() => {
  calculateButtonVisibility()
  hideDefaultContent()
})
</script>
