<template>
  <div class="flex items-center justify-center">
    <div class="size-20 animate-spin text-text-primary">
      <svg
        width="56"
        height="80"
        viewBox="0 0 56 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40 5.24929C40 2.35019 37.6399 -0.0354258 34.7657 0.343948C30.5885 0.89531 26.5135 2.10471 22.695 3.93703C17.2943 6.5286 12.5446 10.3001 8.79657 14.9731C5.04857 19.6461 2.39799 25.1012 1.04054 30.9357C-0.31691 36.7703 -0.346575 42.8352 0.953738 48.6827C2.25405 54.5302 4.85113 60.011 8.55324 64.7205C12.2554 69.4299 16.9679 73.2477 22.3431 75.892C27.7182 78.5362 33.6186 79.9395 39.6087 79.9981C43.8439 80.0395 48.0474 79.408 52.0646 78.1372C54.8287 77.2628 55.9554 74.1018 54.7268 71.4759C53.4982 68.85 50.3771 67.7679 47.5752 68.5123C45.018 69.1917 42.3732 69.526 39.7114 69.5C35.2935 69.4568 30.9418 68.4218 26.9774 66.4716C23.013 64.5213 19.5373 61.7056 16.8069 58.2322C14.0765 54.7588 12.161 50.7166 11.202 46.4038C10.243 42.091 10.2649 37.618 11.266 33.3148C12.2672 29.0116 14.2221 24.9883 16.9864 21.5418C19.7506 18.0953 23.2537 15.3137 27.237 13.4023C29.6369 12.2507 32.1741 11.4327 34.7783 10.9644C37.6316 10.4512 40 8.1484 40 5.24929Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="40"
            y1="1.94267e-06"
            x2="72.5926"
            y2="19.2593"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="currentColor" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>
