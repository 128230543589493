import { ref } from 'vue'

export const useSwipeDownCloser = () => {
  const containerTranslate = ref(0)
  const containerOpacity = ref(1)

  const hideContainer = (hideValue = 200) => {
    containerTranslate.value = hideValue
    containerOpacity.value = 0
  }

  const showContainer = () => {
    containerTranslate.value = 0
    containerOpacity.value = 1
  }

  return {
    containerTranslate,
    containerOpacity,
    hideContainer,
    showContainer
  }
}
