import Base64Encoder from 'crypto-js/enc-base64url'
import HexEncoder from 'crypto-js/enc-hex'
import Utf8Encoder from 'crypto-js/enc-utf8'
import HMAC from 'crypto-js/hmac-sha256'
import type { PictureProcessingOptions, PictureExtension } from 'img-proxy'
import { joinURL } from 'ufo'
import { encode } from 'url-safe-base64'
import { useRuntimeConfig } from '../config'

/** @docs https://docs.imgproxy.net/signing_the_url */

/** Получение сигнатуры — зашифрованного в Base64 результата HMAC-вычисления от ссылки
 * @param processingPath - ссылка на изображение
 * @param secret - секретный ключ, строка
 * @param salt - соль
 * @returns Зашифрованная в URL-Safe Base64 строка
 */
const getProcessingPathSignature = (processingPath: string, secret: string, salt: string) => {
  const payload = HexEncoder.parse(salt)

  payload.concat(Utf8Encoder.parse(processingPath))

  const hmac = HMAC(payload, HexEncoder.parse(secret))

  const signature = encode(Base64Encoder.stringify(hmac)).replace(/\./g, '')

  if (signature.endsWith('=')) return signature.slice(0, -1)

  return signature
}

/** Получениех закодированной в Base64 ссылки на изображение
 * @param url - ссылка на изображение
 * @returns Зашифрованная в Base64 строка
 */
const getEncodedImageUrl = (url: string) => {
  const utf8Words = Utf8Encoder.parse(url)
  const base64 = Base64Encoder.stringify(utf8Words)

  return encode(base64).replace(/\./g, '')
}

const stringifyProcessingOptions = (options?: PictureProcessingOptions) =>
  options
    ? Object.entries(options).reduce(
        (acc, [key, value]) => [acc, [key, value].join(':')].join('/'),
        ''
      )
    : ''

/**  Получение закодированной ссылки с опциями трансформирования
 * @param originalImageUrl - ссылка на изображение
 * @param options
 * @param extension - расширение
 */
const getProcessingPath = (
  originalImageUrl: string,
  extension: PictureExtension,
  options?: PictureProcessingOptions
) => {
  const optionsAString = stringifyProcessingOptions(options)

  return optionsAString + '/' + getEncodedImageUrl(originalImageUrl) + `.${extension}`
}

/** Получение финальной ссылки на изображение
 * @param originalImageUrl - ссылка на изображение
 * @param VITE_APP_IMG_PROXY_BASE_URL
 * @param VITE_APP_IMG_PROXY_SALT
 * @param VITE_APP_IMG_PROXY_KEY
 * @param extension
 * @param options
 */
const getPictureUrl = (
  originalImageUrl: string,
  {
    VITE_APP_IMG_PROXY_BASE_URL,
    VITE_APP_IMG_PROXY_SALT,
    VITE_APP_IMG_PROXY_KEY
  }: Record<string, string>,
  extension: PictureExtension,
  options?: PictureProcessingOptions
) => {
  const processingPath = getProcessingPath(originalImageUrl, extension, options)

  return joinURL(
    VITE_APP_IMG_PROXY_BASE_URL,
    getProcessingPathSignature(processingPath, VITE_APP_IMG_PROXY_KEY, VITE_APP_IMG_PROXY_SALT),
    processingPath
  )
}

const useImgProxy = () => {
  const { VITE_APP_IMG_PROXY_BASE_URL, VITE_APP_IMG_PROXY_SALT, VITE_APP_IMG_PROXY_KEY } =
    useRuntimeConfig()

  try {
    if (!VITE_APP_IMG_PROXY_BASE_URL || !VITE_APP_IMG_PROXY_SALT || !VITE_APP_IMG_PROXY_KEY) {
      throw new Error('IMGPROXY variables is not defined')
    }
    const getImageProxyUrl = (
      imageUrl: string,
      options?: PictureProcessingOptions,
      extension: PictureExtension = 'webp'
    ) =>
      getPictureUrl(
        imageUrl,
        { VITE_APP_IMG_PROXY_BASE_URL, VITE_APP_IMG_PROXY_SALT, VITE_APP_IMG_PROXY_KEY },
        extension,
        options
      )

    return { getImageProxyUrl }
  } catch (e) {
    console.warn('failed to get url for UiPicture ', e)
    const getImageProxyUrl = (imageUrl: string) => imageUrl

    return { getImageProxyUrl }
  }
}

export { useImgProxy }
