// ref https://github.com/primefaces/primevue/blob/master/packages/core/src/utils/DomHandler.js#L299C7-L299C8
import { windowHelpers } from './windowHelpers'

const getHiddenElementDimensions = (element?: HTMLElement) => {
  if (element) {
    const dimensions: { width: number; height: number } = { width: 0, height: 0 }

    element.style.visibility = 'hidden'
    element.style.display = 'block'
    dimensions.width = element.offsetWidth
    dimensions.height = element.offsetHeight
    element.style.display = 'none'
    element.style.visibility = 'visible'

    return dimensions
  }

  return 0
}

export const getElementAbsolutePosition = (element?: HTMLElement, target?: HTMLElement) => {
  if (!element || !target) return

  const elementDimensions = element.offsetParent
    ? { width: element.offsetWidth, height: element.offsetHeight }
    : getHiddenElementDimensions(element)
  const elementOuterHeight = elementDimensions ? elementDimensions.height : 0
  const elementOuterWidth = elementDimensions ? elementDimensions.width : 0
  const targetOuterHeight = target.offsetHeight
  const targetOuterWidth = target.offsetWidth
  const targetOffset = target.getBoundingClientRect()
  const windowScrollTop = windowHelpers.getWindowScrollTop()
  const windowScrollLeft = windowHelpers.getWindowScrollLeft()
  const viewport = windowHelpers.getViewport()
  const sidebarBottomGap = 100

  let top,
    left,
    positionY = 'bottom',
    positionX = 'left',
    origin = 'top'

  if (
    targetOffset.top + targetOuterHeight + elementOuterHeight >
    viewport.height - sidebarBottomGap
  ) {
    top = targetOffset.top + windowScrollTop - elementOuterHeight
    origin = 'bottom'
    positionY = 'top'

    if (top < 0) {
      top = windowScrollTop
    }
  } else {
    top = targetOuterHeight + targetOffset.top + windowScrollTop
  }

  if (targetOffset.left + elementOuterWidth > viewport.width) {
    left = Math.max(0, targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth)
    positionX = 'right'
  } else {
    left = targetOffset.left + windowScrollLeft
  }

  return {
    top,
    left,
    positionY,
    positionX,
    transformOrigin: origin
  }
}
