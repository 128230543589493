import { withQuery, getQuery } from 'ufo'
import { onBeforeUnmount, onMounted } from 'vue'
import { useRoute } from 'vue-router'

/**
 * добавляет возможность отменить какое либо действие нативной навигацией
 * @param queryName - имя query-параметра
 * @param popstateHandler - метод, который вызывается при возврате назад
 */
export const useQueryParamsOpenState = (queryName: string, popstateHandler: () => void) => {
  const route = useRoute()

  const setParamInRouteQuery = () => {
    const url = withQuery(route.fullPath, { [queryName]: 'open' })
    window.history.pushState(null, '', url)
  }

  const popstateListenerHandler = () => {
    const query = getQuery(route.fullPath)

    if (!query[queryName]) {
      popstateHandler()
    }
  }

  onMounted(() => {
    window.addEventListener('popstate', popstateListenerHandler)
  })
  onBeforeUnmount(() => {
    window.removeEventListener('popstate', popstateListenerHandler)
  })

  return {
    setParamInRouteQuery
  }
}
