import type { RuntimeConfig } from './type'

export const useRuntimeConfig = () => Object.freeze(window.config as RuntimeConfig)

export const checkConfig = () => {
  if (!window.config) {
    throw new Error('Отстуствует конфигурация с переменными окружения')
  }

  const undefinedOptions = Object.entries(window.config).filter(([, variable]) => !variable)

  if (undefinedOptions.length) {
    throw new Error(`В конфигурации отсутствуют переменные: ${undefinedOptions.join(' ')}`)
  }
}
