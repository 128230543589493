<template>
  <div>
    <Slider
      :model-value="modelValue"
      :lazy="false"
      :tooltips="false"
      @update:model-value="emit('update:modelValue', $event as PropType['modelValue'])"
    />
  </div>
</template>

<script lang="ts" setup>
import Slider from '@vueform/slider'

type PropType = {
  range?: boolean
  modelValue: number | number[]
}

type EmitType = {
  (e: 'update:modelValue', value: PropType['modelValue']): void
}

withDefaults(defineProps<PropType>(), {
  range: false
})

const emit = defineEmits<EmitType>()
</script>

<style src="@vueform/slider/themes/default.css" />

<style>
.slider-connects {
  @apply bg-tertiary rounded-xl h-2;
}

.slider-connect {
  @apply bg-main h-2.5;
}

.slider-handle {
  @apply !size-9 shadow-quaternary !-top-4;
}

.slider-handle:before {
  @apply absolute content-[''] size-3 bg-main block rounded-full inset-center;
}

.slider-handle:focus {
  @apply shadow-primary;
}

.slider-touch-area {
  width: 200%;
  height: 200%;
  transform: translateX(-25%) translateY(-25%);
}
</style>
