const setBodyStyles = (styles: string) => {
  document.body.style.cssText = styles
}

export const lockScroll = () => {
  const styles = `
    height: 100%;
    overflow: hidden;
  `
  setBodyStyles(styles)
}

export const unlockScroll = () => {
  setBodyStyles('')
}
