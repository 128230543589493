<template>
  <VueEternalLoading :position="position" :margin="margin" :load="loadHandler">
    <template #no-more>
      <div />
    </template>

    <template #loading>
      <slot name="loading">
        <UiLoader class="size=5" />
      </slot>
    </template>

    <template #error="{ retry }">
      <div class="flex flex-col items-center py-5">
        <p class="mb-4 text-center text-xs text-text-fivefold">Не удалось загрузить список</p>
        <UiButton severity="secondary" @click="retry">Повторить попытку</UiButton>
      </div>
    </template>
  </VueEternalLoading>
</template>

<script lang="ts" setup>
import { VueEternalLoading, type LoadAction, type Position } from '@ts-pro/vue-eternal-loading'
import UiButton from './UiButton.vue'
import UiLoader from './UiLoader.vue'

type PropType = {
  handler: (() => Promise<void>) | (() => void)
  allLoaded: boolean
  position?: Position
  margin?: string
}

const props = withDefaults(defineProps<PropType>(), {
  position: 'default',
  margin: '200px'
})

const loadHandler = async ({ loaded, error, noMore }: LoadAction) => {
  try {
    await props.handler()
    props.allLoaded ? noMore() : loaded()
  } catch {
    error()
  }
}
</script>
