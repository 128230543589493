<template>
  <button
    ref="button"
    type="button"
    class="flex w-full items-center justify-center outline-0 will-change-transform"
    :style="{ transform: `translateY(${!noTranslate && buttonTranslate}px)` }"
  >
    <slot v-if="$slots.default" />
    <span v-else class="h-1 w-9 rounded-3xl bg-tertiary" />
  </button>
</template>

<script lang="ts" setup>
import { useSwipe } from '@vueuse/core'
import type { Nullable } from 'ts-helpers'
import { computed, ref } from 'vue'

type PropType = {
  containerHeight: number
  closeThreshold?: number
  maxButtonTranslate?: boolean | number
  noTranslate?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  closeThreshold: 40,
  maxButtonTranslate: false,
  noTranslate: false
})
const emit = defineEmits<EmitType>()
const containerTranslate = defineModel<number>('containerTranslate')
defineModel<number>('containerOpacity')

type SwipeEventEmit = {
  lengthY: number
  lengthX: number
}

type EmitType = {
  (e: 'swipe', data: SwipeEventEmit): void
  (e: 'swipeUp'): void
  (e: 'close'): void
  (e: 'update:containerTranslate', value: number): void
  (e: 'update:containerOpacity', value: number): void
}

const button = ref<Nullable<HTMLElement>>(null)

const createSwipeEmit = (): SwipeEventEmit => ({
  lengthY: lengthY.value,
  lengthX: lengthX.value
})

const buttonTranslate = computed(() => {
  if (!props.maxButtonTranslate || isNaN(props.maxButtonTranslate as number))
    return containerTranslate.value

  return Number(containerTranslate.value) <= Number(props.maxButtonTranslate)
    ? containerTranslate.value
    : props.maxButtonTranslate
})

const { lengthY, lengthX } = useSwipe(button, {
  passive: false,
  threshold: 10,
  onSwipe() {
    if (lengthY.value < 0) {
      const length = Math.abs(lengthY.value)
      const opacity = 1.1 - length / props.containerHeight / (props.closeThreshold / 30)

      emit('update:containerTranslate', length)
      emit('update:containerOpacity', opacity)
      emit('swipe', createSwipeEmit())
    }

    if (lengthY.value > 0) {
      emit('swipeUp')
    }
  },
  onSwipeEnd() {
    const length = Math.abs(lengthY.value)
    const threshold = length / props.containerHeight >= props.closeThreshold / 100

    if (lengthY.value < 0 && threshold) {
      emit('close')
    } else {
      emit('swipeUp')
    }
  }
})
</script>
