import { useRouter } from 'vue-router'

export const useGoBack = () => {
  const router = useRouter()

  const goBack = () => {
    router.options.history.state.back ? router?.go(-1) : router.push('/')
  }

  return {
    goBack
  }
}
