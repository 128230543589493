import type { CacheMap, CacheSetConfig } from './model'

class CacheService {
  private _cacheMap: CacheMap = new Map()

  public get<T>(key: string): T | undefined {
    const cacheItem = this._cacheMap.get(key)

    if (!cacheItem) return

    const { expiresAt } = cacheItem
    const now = Date.now()

    if (expiresAt - now <= 0) {
      this.delete(key)

      return
    }

    return cacheItem.data as T
  }

  public set(key: string, value: CacheSetConfig) {
    const expiresAt = Number(new Date()) + value.lifeTime

    this._cacheMap.set(key, { ...value, expiresAt })
  }

  public delete(key: string) {
    this._cacheMap.delete(key)
  }

  public clear() {
    this._cacheMap.clear()
  }
}

const cache = new CacheService()

export { cache }
