<template>
  <Component
    :is="tag"
    ref="slider"
    class="scrollbar-hidden flex overflow-y-hidden overflow-x-scroll px-3"
  >
    <slot />
  </Component>
</template>

<script lang="ts" setup>
import { useDraggable } from '@vueuse/core'
import type { Nullable } from 'ts-helpers'
import { ref, reactive } from 'vue'

type PropType = {
  tag?: string
}

withDefaults(defineProps<PropType>(), {
  tag: 'div'
})

const slider = ref<Nullable<HTMLElement>>(null)

const pos = reactive({ left: 0, x: 0 })
// отключает событие клика по ссылке, пока идет скролл
const disableClick = ref(false)

useDraggable(slider, {
  axis: 'x',
  onStart(_, event) {
    pos.x = event.clientX
    pos.left = slider.value!.scrollLeft
  },
  onEnd() {
    setTimeout(() => (disableClick.value = false), 10)
  }
})
</script>
