<template>
  <UiSwiper>
    <Swiper
      class="mr-auto w-[95%]"
      :modules="modules"
      effect="cards"
      grab-cursor
      :cards-effect="{ rotate: false, perSlideOffset: 9 }"
    >
      <slot />
    </Swiper>
  </UiSwiper>
</template>

<script setup lang="ts">
import { EffectCards } from 'swiper/modules'
import 'swiper/css/effect-cards'
import { Swiper } from 'swiper/vue'
import UiSwiper from './UiSwiper.vue'

const modules = [EffectCards]
</script>
