export const getMilliseconds = {
  inSeconds(seconds: number): number {
    return 1000 * seconds
  },
  inMinutes(minutes: number): number {
    return this.inSeconds(60) * minutes
  },
  inHours(hours: number): number {
    return this.inMinutes(60) * hours
  },
  inDays(days: number): number {
    return this.inHours(24) * days
  }
}
