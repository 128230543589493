import each from 'lodash/each.js'
import isPlainObject from 'lodash/isPlainObject.js'
import transform from 'lodash/transform.js'
import type { JSONLike } from 'ts-helpers'

/** Разглаживает объект
 * @example
 *  const obj = { a: { b: 'hello', c: true }, d: 2 }
 const expected = { 'a.b': 'hello', 'a.c': true, d: 2 }
 */
export const flattenObject = <T extends JSONLike>(obj: T, delimiter = '-'): JSONLike =>
  transform(
    obj,
    (result: any, value, key) => {
      if (isPlainObject(value)) {
        const nested = flattenObject(<JSONLike>value, delimiter)
        each(nested, (nestedValue, nestedKey) => {
          result[`${key}${delimiter}${nestedKey}`] = nestedValue
        })
      } else {
        result[key] = value
      }
    },
    {} as any
  )
