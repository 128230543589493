export const windowHelpers = {
  getWindowScrollTop: () => {
    const doc = document.documentElement

    return doc.scrollTop - (doc.clientTop || 0)
  },
  getWindowScrollLeft: () => {
    const doc = document.documentElement

    return doc.scrollLeft - (doc.clientLeft || 0)
  },
  getViewport: () => {
    const win = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      w = win.innerWidth || e.clientWidth || g.clientWidth,
      h = win.innerHeight || e.clientHeight || g.clientHeight

    return { width: w, height: h }
  }
}
