import type { Undefinable } from 'ts-helpers'
import { computed, type Ref } from 'vue'

export const usePageInfiniteLoading = <T>(
  pageSize = 5,
  page: Ref<number>,
  items: Ref<Undefinable<T[]>>
) => {
  const showingItems = computed(() => {
    return items.value && items.value.filter((_, count) => count < page.value * pageSize)
  })

  const allItemsIsVisible = computed(
    () => !!items.value && page.value >= items.value.length / pageSize
  )

  const loadNextPage = () => {
    !allItemsIsVisible.value && page.value++
  }

  return {
    showingItems,
    allItemsIsVisible,
    loadNextPage
  }
}
