const getMetaTag = () => {
  return document.querySelector('meta[name=viewport]')
}

const DISABLED = 'width=device-width, initial-scale=1.0, user-scalable=no'
const ENABLED = 'width=device-width, initial-scale=1.0'

export const setUserScale = {
  disable() {
    const tag = getMetaTag()

    tag && tag.setAttribute('content', DISABLED)
  },
  enable() {
    const tag = getMetaTag()

    tag && tag.setAttribute('content', ENABLED)
  }
}
