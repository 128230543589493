<template>
  <span>
    {{ format(model, template, { locale: ru }) }}
  </span>
</template>

<script lang="ts" setup>
import { format } from 'date-fns'
import { ru } from 'date-fns/locale/ru'
import { computed } from 'vue'

type PropType = {
  value: string | Date
  template?: string
}

const props = withDefaults(defineProps<PropType>(), {
  template: 'd MMMM yyyy'
})

const model = computed(() =>
  typeof props.value === 'string' ? new Date(props.value) : props.value
)
</script>
