<template>
  <label class="flex flex-col" :class="{ 'opacity-70': disabled }">
    <span class="flex items-center">
      <input
        type="checkbox"
        :checked="modelValue"
        class="size-0 shrink-0 opacity-0"
        v-bind="{ ...$attrs }"
        :disabled="disabled"
        @click="emit('update:modelValue', !modelValue)"
      />
      <span
        type="button"
        class="flex size-5 items-center justify-center rounded-md border border-checkbox-border text-checkbox-icon transition-colors"
        :class="{
          'border-checkbox-background bg-checkbox-background': modelValue,
          'bg-checkbox-border': disabled,
          'border-error': isInvalidInput
        }"
      >
        <TransitionFade :duration="100">
          <UiIcon v-show="modelValue" name="check" class="size-6" />
        </TransitionFade>
      </span>
      <span class="ml-2 text-xs">
        <slot />
      </span>
    </span>
    <TransitionFade :duration="200">
      <span v-show="isInvalidInput" class="text-xs text-error">{{ errors[0] }}</span>
    </TransitionFade>
  </label>
</template>

<script lang="ts" setup>
import { TransitionFade } from '@morev/vue-transitions'
import { computed } from 'vue'
import UiIcon from './UiIcon.vue'

type PropType = {
  modelValue: boolean
  disabled?: boolean
  errors?: string[]
}

type EmitType = {
  (e: 'update:modelValue', value: boolean): void
}

const props = withDefaults(defineProps<PropType>(), {
  errors: () => []
})
const emit = defineEmits<EmitType>()

defineOptions({
  inheritAttrs: true
})

const isInvalidInput = computed(() => props.errors.length)
</script>
