<template>
  <Component :is="tag || severity" :class="computedClasses">
    <slot />
  </Component>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

type PropType = {
  severity?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  size?: 'big' | 'medium' | 'default'
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const props = withDefaults(defineProps<PropType>(), {
  severity: 'h1',
  size: 'default',
  tag: undefined
})

const classesSeverityMap: Record<NonNullable<PropType['severity']>, string> = {
  h1: 'font-bold',
  h2: 'text-2xl font-semibold',
  h3: 'text-xl font-medium',
  h4: 'text-xl font-semibold',
  h5: 'text-base font-semibold',
  h6: 'text-base font-medium'
}

const classesSizeMap: Record<NonNullable<PropType['size']>, string> = {
  big: 'text-5xl',
  medium: 'text-4xl',
  default: 'text-3xl'
}

const computedClasses = computed(() => [
  classesSeverityMap[props.severity],
  props.severity === 'h1' ? classesSizeMap[props.size] : ''
])
</script>
