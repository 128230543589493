import { useRouter } from 'vue-router'
import type { ErrorResponse } from '../error'

export const useHandlePageError = () => {
  const router = useRouter()

  const handlePageError = (error: ErrorResponse | any) => {
    if (!error.code) return

    if (error.code === 404) {
      return router.replace('/404')
    }

    if (error.code === 500) {
      return router.replace('/500')
    }
  }

  return {
    handlePageError
  }
}
