<template>
  <svg v-bind="{ ...$attrs }">
    <use :href="`/svg-sprite/sprite.svg#${iconHref}`" />
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

type PropType = {
  name: string
  outline?: boolean
  solid?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  outline: true,
  solid: false
})

const iconHref = computed(() => {
  const prefix = props.solid ? 'solid' : props.outline ? 'outline' : ''

  return `${prefix}--${props.name}`
})
</script>
