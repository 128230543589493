import { getMapFromObject } from '../../../lib'
import type { ErrorCodes, ErrorResponse } from '../model'

export const defaultErrorsHandbook: Map<ErrorCodes, ErrorResponse> = getMapFromObject<
  ErrorCodes,
  ErrorResponse
>({
  400: { code: 400, message: 'Неверный запрос' },
  401: { code: 401, message: 'Вы не авторизованы' },
  403: { code: 403, message: 'Доступ к ресурсу запрещён' },
  404: { code: 404, message: 'Не найдено' },
  408: { code: 408, message: 'Запрос истёк по времени' },
  429: { code: 429, message: 'Слишком много запросов' },
  500: { code: 500, message: 'Внутренняя ошибка сервера' },
  503: { code: 503, message: 'Сервис недоступен' },
  504: { code: 504, message: 'Шлюз не отвечает' }
})
