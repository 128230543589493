<template>
  <Component
    :is="tag"
    :type="type"
    :class="[
      'relative rounded-[100px] font-normal transition-colors',
      { '!bg-transparent': transparent },
      computedClasses
    ]"
    :disabled="disabled"
    @click="!loading && emit('click', $event)"
  >
    <span v-if="loading" class="absolute inset-center">
      <img src="@/assets/icons/outline/loader.svg" alt="loading" class="animate-spin" />
    </span>
    <slot />
  </Component>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

type PropsType = {
  severity?: 'primary' | 'secondary' | 'tertiary'
  size?: 'big' | 'small'
  type?: 'button' | 'submit' | 'reset'
  loading?: boolean
  icon?: boolean
  text?: boolean
  disabled?: boolean
  transparent?: boolean
  tag?: string
}

type EmitType = {
  (e: 'click', event: MouseEvent): void
}

const props = withDefaults(defineProps<PropsType>(), {
  severity: 'primary',
  size: 'big',
  type: 'button',
  transparent: false,
  disabled: false,
  tag: 'button'
})
const emit = defineEmits<EmitType>()

const severityClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: 'bg-button-primary hover:bg-button-primary-hover text-button-primary-text',
  secondary: 'bg-button-secondary hover:bg-button-secondary-hover text-button-secondary-text',
  tertiary: 'bg-button-tertiary hover:bg-button-tertiary-hover text-button-tertiary-text'
}

const sizeClassesMap: Record<NonNullable<PropsType['size']>, string> = {
  big: 'p-4 text-base',
  small: 'p-3 text-xs'
}

const defaultTextClasses = '!bg-transparent !p-0 hover:underline'
const textClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: `${defaultTextClasses} text-text-main`,
  secondary: `${defaultTextClasses} text-text-secondary`,
  tertiary: `${defaultTextClasses} text-text-tertiary`
}

const defaultLoadingClasses = 'text-transparent'
const loadingClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: `${defaultLoadingClasses} ${severityClassesMap['primary']} hover:!bg-button-primary`,
  secondary: `${defaultLoadingClasses} ${severityClassesMap['secondary']} hover:!bg-button-secondary`,
  tertiary: `${defaultLoadingClasses} ${severityClassesMap['tertiary']} hover:!bg-button-tertiary`
}

const defaultIconClasses = 'flex items-center justify-center rounded-full !p-1 text-none'

const disabledClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: '!bg-button-primary-disabled',
  secondary: '!bg-button-secondary-disabled',
  tertiary: '!bg-button-tertiary-disabled disabled:opacity-70'
}

const computedClasses = computed(() =>
  [
    severityClassesMap[props.severity],
    sizeClassesMap[props.size],
    props.text ? textClassesMap[props.severity] : '',
    props.loading ? loadingClassesMap[props.severity] : '',
    props.icon ? defaultIconClasses : '',
    props.disabled ? disabledClassesMap[props.severity] : ''
  ].join(' ')
)
</script>
